// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { YMInitializer } from 'react-yandex-metrika';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import routes from './constants/routes';
import CookieBanner from './components/CookieBanner';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

const METRIKA_ID = process.env.REACT_APP_METRIKA_ID;

const Metrics = () => {
	const location = useLocation();

	useEffect(() => {
		if (window.ym && METRIKA_ID) {
			window.ym(Number(METRIKA_ID), 'hit', location.pathname);
		}
	}, [location]);

	return METRIKA_ID ? <YMInitializer accounts={[Number(METRIKA_ID)]} options={{ webvisor: true, clickmap: true }} /> : null;
};

const AppContent = () => {
	const location = useLocation();
	const excludeOverlay = ['/terms-of-service', '/privacy-policy'].includes(location.pathname);

	return (
		<div className="app-content" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
			<Metrics />
			{!excludeOverlay && <CookieBanner />}
			<ScrollToTop />
			<Navbar />
			<main style={{ flex: 1 }}>
				<Routes>
					{routes.map((route, index) => (
						<Route key={index} path={route.path} element={route.element} />
					))}
				</Routes>
			</main>
			<Footer />
			<ToastContainer
				position="bottom-right"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
			/>
		</div>
	);
};

const App = () => (
	<Router>
		<AppContent />
	</Router>
);

export default App;

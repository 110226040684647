// src/components/Footer.js
import React from 'react';
import { Container, Typography, Box, Grid, TextField, Button, Link } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';

const notificationBaseUrl = process.env.REACT_APP_NOTIFICATION_BASE_URL;

const Footer = () => {
	const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;
	const logoUrl = `${storageBaseUrl}/images/shared/logo.footer.svg`;

	const initialValues = {
		email: '',
	};

	const validationSchema = Yup.object({
		email: Yup.string()
			.email('Некорректный email адрес')
			.matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Некорректный email адрес')
			.required('Обязательно для заполнения'),
	});

	const handleSubmit = async (values, { setSubmitting, setErrors, resetForm }) => {
		try {
			await axios.post(`${notificationBaseUrl}/user`, { email: values.email });
			resetForm();
			toast.success('Подписка успешна!');
		} catch (error) {
			if (error.response && error.response.status === 400) {
				setErrors({ email: 'Этот email уже подписан.' });
				toast.error('Этот email уже подписан.');
			} else {
				toast.error('Ошибка при отправке запроса. Попробуйте снова.');
			}
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<Box component="footer" sx={{ bgcolor: 'background.paper', py: 6 }}>
			<Container maxWidth="lg">
				<Grid container spacing={4} justifyContent="space-between">
					<Grid item xs={12} md={4}>
						<Box
							component="img"
							src={logoUrl}
							alt="GOFINSIGHTS logo"
							sx={{ height: 40, width: 'auto', mb: 2 }}
						/>
						<Typography variant="body1">
							Оставайтесь в курсе наших последних обновлений и релизов, подписавшись на нашу рассылку.
						</Typography>
						<Formik
							initialValues={initialValues}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
						>
							{({ isSubmitting }) => (
								<Form>
									<Box display="flex" flexDirection="column" mt={2}>
										<Field
											as={TextField}
											label="Ваш email"
											name="email"
											variant="outlined"
											size="small"
											sx={{ mb: 2 }}
											fullWidth
											helperText={<ErrorMessage name="email" component="div" style={{ color: 'red' }} />}
										/>
										<Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
											Подписаться
										</Button>
									</Box>
								</Form>
							)}
						</Formik>
						<Typography variant="body2" color="textSecondary" mt={2}>
							Подписываясь, вы соглашаетесь с нашей Политикой конфиденциальности и соглашаетесь получать обновления от нашей компании.
						</Typography>
					</Grid>
					<Grid item xs={12} md={2}>
						<Typography variant="h6" gutterBottom>
							Разделы
						</Typography>
						<Link href="/" variant="body1" display="block" gutterBottom>
							Главная
						</Link>
						<Link href="/patterns" variant="body1" display="block" gutterBottom>
							Паттерны
						</Link>
						<Link href="/trainer" variant="body1" display="block" gutterBottom>
							Тренажер
						</Link>
						{/* <Link href="/login" variant="body1" display="block" gutterBottom>
							Вход
						</Link> */}
					</Grid>
					<Grid item xs={12} md={3}>
						<Typography variant="h6" gutterBottom>
							Связаться с нами
						</Typography>
						<Link href="https://t.me/Gofinsights_bot" variant="body1" display="block" gutterBottom>
							Telegram для отзывов и предложений
						</Link>
						<Link href="mailto:support@gofinsights.ru" variant="body1" display="block" gutterBottom>
							Email: support@gofinsights.ru
						</Link>
					</Grid>
				</Grid>
				<Box mt={4} display="flex" flexDirection={{ xs: 'column', sm: 'row' }} justifyContent="space-between" alignItems="center">
					<Typography variant="body2" color="textSecondary" align="center">
						&copy; {new Date().getFullYear()} GOFINSIGHTS. Все права защищены.
					</Typography>
					<Box display="flex" flexDirection={{ xs: 'column', sm: 'row' }} alignItems="center">
						<Link href="/privacy-policy" variant="body2" color="textSecondary" sx={{ my: 1, mx: { sm: 1 } }}>
							Политика конфиденциальности
						</Link>
						<Link href="/terms-of-service" variant="body2" color="textSecondary" sx={{ my: 1, mx: { sm: 1 } }}>
							Условия использования
						</Link>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default Footer;
import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Tabs, Tab, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { patternsCreational, patternsStructural, patternsBehavioral } from '../constants/patterns';
import { renderCollage } from '../components/patterns/renderCollage';
import { textVariants } from '../constants/motionVariants';
import TrainerSection from '../components/trainer/TrainerSection';
import { Helmet } from 'react-helmet';

const Patterns = () => {
	const location = useLocation();
	const [activeTab, setActiveTab] = useState(0);

	// При монтировании (и изменении хэша) установить активную вкладку
	useEffect(() => {
		if (location.hash) {
			if (location.hash === "#generative") {
				setActiveTab(0);
			} else if (location.hash === "#structural") {
				setActiveTab(1);
			} else if (location.hash === "#behavioral") {
				setActiveTab(2);
			}
		} else {
			setActiveTab(0);
		}
	}, [location.hash]);

	const handleChange = (event, newValue) => {
		setActiveTab(newValue);
		// Обновляем хэш в URL без перезагрузки страницы
		if (newValue === 0) {
			window.history.replaceState(null, null, "#generative");
		} else if (newValue === 1) {
			window.history.replaceState(null, null, "#structural");
		} else if (newValue === 2) {
			window.history.replaceState(null, null, "#behavioral");
		}
	};

	const renderContent = () => {
		switch (activeTab) {
			case 0:
				return (
					<Box
						component={motion.div}
						variants={textVariants}
						initial="hidden"
						animate="visible"
					>
						<Typography id="generative-patterns" variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
							Порождающие паттерны сосредоточены на способах создания объектов. Они абстрагируют процесс инстанцирования объектов, позволяя системе быть более независимой от конкретных классов. Эти паттерны важны для повышения гибкости и повторного использования кода.
						</Typography>
						{renderCollage(patternsCreational)}
					</Box>
				);
			case 1:
				return (
					<Box
						component={motion.div}
						variants={textVariants}
						initial="hidden"
						animate="visible"
					>
						<Typography id="structural-patterns" variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
							Структурные паттерны описывают, как классы и объекты могут быть объединены в более крупные структуры. Они помогают создать гибкие и масштабируемые системы, упрощая управление сложностью и улучшая архитектуру приложения.
						</Typography>
						{renderCollage(patternsStructural)}
					</Box>
				);
			case 2:
				return (
					<Box
						component={motion.div}
						variants={textVariants}
						initial="hidden"
						animate="visible"
					>
						<Typography id="behavioral-patterns" variant="body1" component="p" sx={{ textAlign: 'justify', mb: 4 }}>
							Поведенческие паттерны фокусируются на взаимодействиях между объектами и распределении обязанностей между ними. Эти паттерны организуют алгоритмы и потоки управления, обеспечивая эффективное и гибкое поведение приложений.
						</Typography>
						{renderCollage(patternsBehavioral)}
					</Box>
				);
			default:
				return null;
		}
	};

	return (
		<Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
			<Helmet>
				<title>Паттерны проектирования: порождающие, структурные и поведенческие | GOFINSIGHTS</title>
				<meta
					name="description"
					content="Изучите порождающие, структурные и поведенческие паттерны проектирования. Узнайте, как эти шаблоны могут улучшить архитектуру вашего приложения и повысить качество кода."
				/>
				<meta
					name="keywords"
					content="паттерны проектирования, порождающие паттерны, структурные паттерны, поведенческие паттерны, шаблоны проектирования, разработка ПО, GOFINSIGHTS"
				/>
				<meta name="author" content="GOFINSIGHTS" />
				<meta name="robots" content="index, follow" />
				<meta property="og:title" content="Паттерны проектирования: порождающие, структурные и поведенческие | GOFINSIGHTS" />
				<meta
					property="og:description"
					content="Подробное изучение паттернов проектирования – от порождающих до поведенческих. Узнайте, как применять эти шаблоны для улучшения архитектуры и качества вашего кода."
				/>
				<meta property="og:url" content="https://gofinsights.com/patterns" />
				<meta property="og:type" content="article" />
				<meta property="og:locale" content="ru_RU" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				<meta name="theme-color" content="#ffffff" />
			</Helmet>

			<Typography variant="h3" component="h1" gutterBottom sx={{ textAlign: 'center' }}>
				Паттерны проектирования
			</Typography>
			<Tabs
				value={activeTab}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				variant="scrollable"
				scrollButtons="auto"
				sx={{
					display: 'flex',
					justifyContent: { xs: 'flex-start', md: 'center' },
					'& .MuiTabs-flexContainer': {
						justifyContent: { xs: 'flex-start', md: 'center' }
					}
				}}
			>
				<Tab label="Порождающие" />
				<Tab label="Структурные" />
				<Tab label="Поведенческие" />
			</Tabs>
			<Box sx={{ mt: 4 }}>
				{renderContent()}
			</Box>
			<TrainerSection />
			<Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 1, justifyContent: 'space-between', mt: 4 }}>
				<Button variant="outlined" component={Link} to="/" sx={{ mb: { xs: 1, md: 0 } }}>
					На главную
				</Button>
				<Button variant="contained" color="primary" component={Link} to="/learn-more-patterns">
					Узнать больше о паттернах
				</Button>
			</Box>
		</Container>
	);
};

export default Patterns;

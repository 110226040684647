import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { motion } from 'framer-motion';
import { containerVariants, imageVariants, textVariants } from '../constants/motionVariants';

const UnderConstruction = () => {
	const storageBaseUrl = process.env.REACT_APP_STORAGE_BASE_URL;
	const imageUrl = `${storageBaseUrl}/images/shared/underConstruction.svg`;
	const arrowImageUrl = `${storageBaseUrl}/images/shared/arrow.svg`

	return (
		<Container
			component={motion.div}
			variants={containerVariants}
			initial="hidden"
			animate="visible"
			sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}
		>
			<Grid container spacing={2} alignItems="center" justifyContent="center">
				<Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box
						component={motion.img}
						src={imageUrl}
						alt="Under Construction"
						sx={{ maxWidth: '100%', height: 'auto' }}
						variants={imageVariants}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2} alignItems="center" justifyContent="center">
				<Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
					<Box
						component={motion.img}
						src={arrowImageUrl}
						alt="Arrow subscribe"
						sx={{ maxWidth: '100%', height: 'auto' }}
						variants={imageVariants}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography
						variant="h4"
						gutterBottom
						component={motion.div}
						variants={textVariants}
					>
						Новый раздел в разработке!
					</Typography>
					<Typography
						variant="body1"
						gutterBottom
						component={motion.div}
						variants={textVariants}
					>
						Оставьте свой email и будьте в числе первых, кто узнает о его запуске.
					</Typography>
				</Grid>
			</Grid>
		</Container>
	);
};

export default UnderConstruction;

import React, { useState } from 'react';
import { Box, Drawer, List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const patterns = [
	{
		category: 'Порождающие',
		url: '/patterns#generative',
		items: [
			{ name: 'Одиночка', url: '/patterns/singleton' },
			{ name: 'Строитель', url: '/patterns/builder' },
			{ name: 'Абстрактная фабрика', url: '/patterns/abstract-factory' },
			{ name: 'Фабричный метод', url: '/patterns/factory-method' },
			{ name: 'Прототип', url: '/patterns/prototype' }
		]
	},
	{
		category: 'Структурные',
		url: '/patterns#structural',
		items: [
			{ name: 'Адаптер', url: '/patterns/adapter' },
			{ name: 'Мост', url: '/patterns/bridge' },
			{ name: 'Компоновщик', url: '/patterns/composite' },
			{ name: 'Декоратор', url: '/patterns/decorator' },
			{ name: 'Фасад', url: '/patterns/facade' },
			{ name: 'Легковес', url: '/patterns/flyweight' },
			{ name: 'Заместитель', url: '/patterns/proxy' }
		]
	},
	{
		category: 'Поведенческие',
		url: '/patterns#behavioral',
		items: [
			{ name: 'Цепочка обязанностей', url: '/patterns/chain-of-responsibility' },
			{ name: 'Команда', url: '/patterns/command' },
			{ name: 'Интерпретатор', url: '/patterns/interpreter' },
			{ name: 'Итератор', url: '/patterns/iterator' },
			{ name: 'Посредник', url: '/patterns/mediator' },
			{ name: 'Хранитель', url: '/patterns/memento' },
			{ name: 'Наблюдатель', url: '/patterns/observer' },
			{ name: 'Состояние', url: '/patterns/state' },
			{ name: 'Стратегия', url: '/patterns/strategy' },
			{ name: 'Шаблонный метод', url: '/patterns/template-method' },
			{ name: 'Посетитель', url: '/patterns/visitor' }
		]
	}
];

const CatalogSidebar = () => {
	const [drawerOpen, setDrawerOpen] = useState(false);

	const toggleDrawer = (open) => (event) => {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
		setDrawerOpen(open);
	};

	const list = () => (
		<Box
			sx={{ width: 250, padding: '16px' }}
			role="presentation"
			onClick={toggleDrawer(false)}
			onKeyDown={toggleDrawer(false)}
		>
			{patterns.map((patternCategory, index) => (
				<Box key={index} sx={{ marginBottom: '16px' }}>
					<Box
						component={Link}
						to={patternCategory.url}
						sx={{ textDecoration: 'none', color: 'inherit' }}
					>
						<Typography variant="h6">{patternCategory.category}</Typography>
					</Box>
					<List>
						{patternCategory.items.map((item, idx) => (
							<ListItem button component={Link} to={item.url} key={idx}>
								<ListItemText primary={item.name} />
							</ListItem>
						))}
					</List>
				</Box>
			))}
		</Box>
	);

	return (
		<div>
			<IconButton
				onClick={toggleDrawer(true)}
				sx={{
					position: 'fixed',
					top: '50%',
					right: 0,
					transform: 'translateY(-50%)',
					backgroundColor: 'rgba(255, 255, 255, 0.7)', // светлый цвет с прозрачностью
					color: 'primary.main',
					borderRadius: '4px 0 0 4px',
					padding: '8px',
					zIndex: 1201, // to ensure it's above other elements
					'&:hover': {
						backgroundColor: 'rgba(255, 255, 255, 0.9)', // более насыщенный цвет при наведении
					}
				}}
			>
				<MenuOpenIcon />
			</IconButton>
			<Drawer anchor='right' open={drawerOpen} onClose={toggleDrawer(false)}>
				{list()}
			</Drawer>
		</div>
	);
};

export default CatalogSidebar;
